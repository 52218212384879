<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span span v-if="formModel.id"
                >{{ mode === "edit" ? "修改" : "" }}{{ formModel.itemName }}
           </span
                >
                <span span v-if="!formModel.id">创建数据项</span>
            </div>

        </div>



        <a-form-model :rules="validModel" ref="ruleForm" :layout="form.layout" :model="formModel"
                      v-bind="formItemLayout">

            <a-form-model-item label="数据项目编码" prop="itemCode">
                <a-input placeholder="请输入项目编码" v-model="formModel.itemCode"></a-input>
            </a-form-model-item>

            <a-form-model-item label="数据项目名称" prop="itemName">
                <a-input placeholder="请输入项目名称" v-model="formModel.itemName"></a-input>
            </a-form-model-item>

            <a-form-model-item label="顶级数据项" prop="isTop">
                <a-select v-model="formModel.isTop" :defaultValue="isTop" >
                    <a-select-option key="1" value="1">是</a-select-option>
                    <a-select-option key="0" value="0">否</a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item label="排序依据" prop="sortCode">
                <a-input v-model="formModel.sortCode" placeholder="请输入"></a-input>
            </a-form-model-item>

            <a-form-model-item label="从属数据项" v-if="isTop == '0'" prop="parentId">

                <a-tree-select
                        v-model="formModel.parentId"
                        show-search
                        style="width: 100%"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        placeholder="请选择从属数据项"
                        allow-clear
                        :treeData="curDataItemTree"
                        :replaceFields="fieldMap"
                >
                </a-tree-select>
            </a-form-model-item>

            <a-form-model-item label="数据项目值" v-if="isTop == '0'" prop="itemValue">
                <a-input v-model="formModel.itemValue" placeholder="请输入数据项目值"></a-input>
            </a-form-model-item>

            <a-form-model-item label="数据项目描述" prop="description">
                <a-textarea v-model="formModel.description" placeholder="请输入数据项目描述"></a-textarea>
            </a-form-model-item>


        </a-form-model>
        <div class="modal-footer">
            <a-button type="danger" @click="cancle">取消</a-button>
            <a-button type="primary" @click="onSubmit">保存</a-button>

        </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";

    import {message} from "ant-design-vue";
    import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
    import Bus from "@/shared/bus/bus";
    export default {
        name: "data-item-form",
        mixins: [AppComponentBase, ModalComponentBase],
        data() {
            return {
                fieldMap: {
                    'value': 'id'
                },
                form: {
                    layout: 'horizontal',
                },
                mode:'create',
                curDataItemTree: [],
                spinning: false,
                // 表单
                formLayout: "horizontal",
                // 选中的权限过滤
                selectedPermission: [],
                //isTop: this.cacl,
                formModel: {
                    id: '',
                    itemName: '',
                    itemValue: '',
                    itemCode: '',
                    isTree: 0,
                    isNav: 0,
                    sortCode: 0,
                    description: '',
                    parentId: '',
                    isTop:''
                },
                validModel: {
                    //Id: '',
                    itemName: [
                        {required: true, message: '请输入!'}
                    ],
                    itemValue: [
                        {required: true, message: '请输入!'}
                    ],
                    itemCode: [
                        {required: true, message: '请输入!'}
                    ],
                    sortCode: [
                        {required: true, message: '请输入!'}
                    ],

                    description: [
                        {required: true, message: '请输入!'}
                    ],
                    parentId: [
                        {required: true, message: '请输入!'}
                    ],
                    // //  isTop: 1
                },
            };
        },

        components: {},
        created() {
            console.log('created')
            dataItemApi.getTreeData('', '').then((data) => {
                console.log(data)
                this.curDataItemTree = data.items
            }).catch((error) => {
                console.log('获取数据失败！！！')
            })
            //console.log('fullData')
            this.fullData(); // 模态框必须,填充数据到data字段
        },
        computed: {
            isTop:function(){
                if (this.formModel.isTop == ''){
                    if (this.formModel.parentId == '' ||this.formModel.parentId == null){
                        this.updateIsTop('1')
                        return '1'
                    }
                    this.updateIsTop('0')
                    return '0'
                }
                return this.formModel.isTop
            },
            formItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        labelCol: {span: 4},
                        wrapperCol: {span: 14},
                    }
                    : {};
            },
            buttonItemLayout() {

                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        wrapperCol: {span: 14, offset: 4},
                    }
                    : {};
            },
        },
        methods: {

            updateIsTop(value){
                console.log('updateIsTop!!!')
                 this.formModel.isTop = value
            },
            cancle() {
                this.close()
            },
            onSubmit() {
                this.$refs.ruleForm.validate(valid => {
                    console.log(valid)
                    if (!valid) {
                        message.error('表单验证失败，请检查后重新提交！')
                        return false;
                    }
                    dataItemApi.save(this.formModel).then((data) => {
                        this.$notification["success"]({
                            message: '操作成功',
                        });

                        this.close()
                    }).catch((error) => {
                        console.log('-----oh 我的玉帝 报错了---------')
                        console.log(error)
                    })
                });

            },
            updateSpinning(newV) {
                if (newV === "0") {
                    this.spinning = false;
                } else {
                    this.spinning = true;
                }
            },
            tabChange(activeKey) {
                this.activeKey = activeKey;
                switch (activeKey) {
                    case "1":
                        // do sth...
                        break;
                    case "2":
                        this.$refs.seal.getData(this.sealUrl.getPaged);
                        break;
                    case "3":
                        this.$refs.account.getData(this.accountUrl.getPaged);
                        break;
                    case "4":
                        this.$refs.realname.getData(this.realNameUrl.getPaged);
                        break;
                }
            },
        },
    };
</script>

<style scoped lang="less">
    .modal-header {
        margin-bottom: 0;

        .anticon {
            margin-right: 10px;
        }
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
